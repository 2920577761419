import React, { useState } from "react";
import {
  contactForm,
  contactRow,
  contactColumn,
  hp,
  answer,
  // success,
  error,
  introTextContainer,
} from "./contact-form.module.scss";

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    formname: "",
    firma: "",
    name: "",
    email: "",
    tel: "",
    message: "",
    subject: `Anfrage für den Zugang von unseren Live Demos`,
  });
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [isPrivateEmail, setIsPrivateEmail] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.formname !== "") {
      return;
    }
    if (
      !!formData.email.includes("@yahoo") ||
      !!formData.email.includes("@gmail") ||
      !!formData.email.includes("@bluewin") ||
      !!formData.email.includes("@hotmail")
    ) {
      setIsPrivateEmail(true);
      return;
    } else {
      setIsPrivateEmail(false);
    }

    try {
      setIsLoading(true);
      const response = await fetch(`/api/form`, {
        method: `POST`,
        body: JSON.stringify(formData),
        headers: {
          "content-type": `application/json`,
        },
      });

      if (!response.ok) {
        const message =
          "Entschuldigung, bei der Übermittlung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal oder senden Sie Ihre Anfrage direkt an: <a href='mailto:info@tie.ch'>info@tie.ch</a>.";
        throw new Error(message);
      }

      const data = await response.json();

      setFormData({
        ...formData,
        formResult: data,
        formSuccess: true,
        formError: false,
        firma: "",
        name: "",
        email: "",
        tel: "",
        message: "",
      });
      setIsLoading(false);
    } catch (error) {
      setFormData({
        ...formData,
        formResult: error,
        formSuccess: false,
        formError: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {!!formData.formSuccess ? (
        <div className={introTextContainer}>
          <div style={{ marginBottom: 32 }}>
            <h2 style={{ marginBottom: 8 }}>
              Wir haben Ihre Anfrage erhalten.
            </h2>
            <p>
              Wir werden uns schnellstmöglich um Ihren Demo-Zugang kümmern und
              Ihnen diesen per E-Mail weiterleiten.
            </p>
            <p>
              Mit freundlichen Grüssen <br />
              the i-engineers
            </p>
          </div>
        </div>
      ) : (
        <div className={introTextContainer}>
          <div style={{ marginBottom: 32 }}>
            <h2 style={{ marginBottom: 8 }}>
              Beziehen Sie jetzt Ihren Demo Zugang
            </h2>
            <p>
              Nachdem Sie das Formular ausgefüllt haben und den Vorgang mit dem
              Button "Demo Account bestellen" abgeschlossen haben, werden wir
              Ihre Anfrage so schnell wie möglich bearbeiten und Ihnen per
              E-Mail die Login-Daten für den Demo-Account zusenden. Mit diesem
              Zugang können Sie unsere Demos in aller Ruhe testen.
            </p>
          </div>

          <form
            className={contactForm}
            name={`Anfrage per Kontaktformular`}
            onSubmit={handleSubmit}
          >
            <input
              className={hp}
              type="text"
              name="formname"
              aria-label="Dieses Feld nicht ausfüllen"
              placeholder="Form Name"
              value={formData.formname}
              onChange={handleChange}
            />
            <div className={contactRow}>
              <div className={contactColumn}>
                <input
                  type="text"
                  name="firma"
                  aria-label="Firma"
                  placeholder="Firma*"
                  required
                  value={formData.firma}
                  onChange={handleChange}
                />
              </div>
              <div className={contactColumn}>
                <input
                  type="text"
                  name="name"
                  aria-label="Name"
                  placeholder="Name*"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={contactRow}>
              <div className={contactColumn}>
                <input
                  type="tel"
                  name="tel"
                  aria-label="Telefon"
                  placeholder="Telefon"
                  value={formData.tel}
                  onChange={handleChange}
                />
              </div>
              <div className={contactColumn}>
                <input
                  type="email"
                  name="email"
                  aria-label="E-Mail"
                  placeholder="E-Mail*"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className={`${isPrivateEmail ? error : ""}`}
                />
                {!!isPrivateEmail ? (
                  <div className={error}>
                    Bitte verwenden Sie keine Private E-Mail-Adresse
                  </div>
                ) : null}
              </div>
            </div>
            <div className={contactRow}>
              <div className={contactColumn}>
                <textarea
                  name="message"
                  aria-label="Mitteilung"
                  placeholder="Mitteilung"
                  rows="6"
                
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={contactRow}>
              <div className={contactColumn}>
                <label>
                  <input
                    type="checkbox"
                    checked={conditionsAccepted}
                    required
                    onChange={() => setConditionsAccepted(!conditionsAccepted)}
                  />
                  the i-engineers AG verpflichtet sich, Ihre Privatsphäre zu
                  schützen und zu respektieren.{" "}
                  <small>
                    Wir verwenden Ihre persönlichen Daten nur zur Verwaltung
                    Ihres Kontos und zur Bereitstellung der von Ihnen
                    angeforderten Produkte und Dienstleistungen. Von Zeit zu
                    Zeit möchten wir Sie über unsere Produkte und
                    Dienstleistungen sowie andere Inhalte, die für Sie von
                    Interesse sein könnten, informieren.
                  </small>
                </label>
              </div>
            </div>

            <button type="submit" disabled={isLoading || !conditionsAccepted}>
              {isLoading ? "Senden..." : "Demo Account bestellen"}
            </button>

            {!!formData.formError && (
              <div
                className={`${answer} ${error}`}
                dangerouslySetInnerHTML={{ __html: formData.formResult }}
              />
            )}
          </form>
        </div>
      )}
    </>
  );
};
